<template>
	<div v-if="authed">
		<v-data-table :headers="headers" :items="items" item-key="_id"
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px">
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="月份" readonly v-bind="attrs"
								v-on="on" dense hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker type="month" locale="zh-TW" v-model="pdate1" @input="menu1=false" @change="fetchData" scrollable></v-date-picker>
					</v-menu>
					<v-select dense hide-details label="工作项" :items="stages" v-model="stage" style="flex: 0 1 auto;max-width:150px;" @change="fetchData"/>
				</div>
				<v-divider/>
			</template>
		</v-data-table>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
export default {
	data() {
		return {
			authed: false,
			loading: false,
			headers: [
				{text:'姓名', value:'name', width:100, sortable:false},
				{text:'月合计', value:'sum', width:100},
			],
			items: [],
			stages: [
				{text:'收讫检查单', value:'eeaccepted'},
				{text:'术前评估', value:'evalConclusion'},
				{text:'手术结果', value:'operative'},
				{text:'术中计费', value:'charging'},
				{text:'病理上传', value:'pathologyUpload'},
				{text:'病理解读', value:'pathology'},
			],
			stage: '',
			menu1: false,
			pdate1: new Date().toISOString().substr(0, 7),
		}
	},
	created() {
		this.authed = this.$hasPrivilege(['统计']);
		if (!this.authed) return;
	},
	methods: {
		async fetchData() {
			if (!this.stage) return;
			const t1 = new Date(this.pdate1 + '-01T00:00:00+08:00');
			const t2 = new Date(t1.getFullYear(), t1.getMonth()+1, 0, 23, 59, 59);
			this.headers = this.$options.data().headers;
			for (let i = 1; i <= t2.getDate(); ++i) {
				this.headers.push({text:i, value:`d${i}`, width:48, sortable:false});
			}
			this.loading = true;
			try {
				const res = await this.$tcbapp.callFunction({name:"wp2mp",
					data:{
						funcname:'getStat',
						data: {
							stage: this.stage,
							timeBegin: t1,
							timeEnd: t2,
						}
					}
				});
				this.items = res.result;
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
	},
}
</script>